import { icons as IconList } from "../../utils/icon";
import { Icons as IconType } from "../../types/icon";
interface IconProps {
  icon: IconType;
  width?: number;
  height?: number;
}

const Icon: React.FC<IconProps> = ({ icon, width, height }) => {
  // eslint-disable-next-line @next/next/no-img-element
  return <img src={IconList[icon]} width={width} height={height} alt={icon} />;
};

export default Icon;
