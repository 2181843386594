import { Icons } from "../types/icon";
// import FullLogoGreen from "../../public/assets/icons/inawo-logo-big.svg";
// import Cash from "../../public/assets/icons/cash.svg";
// import Hurray from "../../public/assets/icons/hurray-gold.svg";
// import FullLogoWhite from "../../public/assets/icons/logo-full-white.svg";
// import SmallLogo from "../../public/assets/icons/logo-small-white.svg";

export const icons: Record<Icons, string> = {
  fullLogoGreen: "/assets/icons/inawo-logo-big.svg",
  fullLogoWhite: "/assets/icons/logo-full-white.svg",
  cash: "/assets/icons/cash.svg",
  hurray: "/assets/icons/hurray-gold.svg",
  smallLogo: "/assets/icons/logo-small-white.svg",
};
