import Link from "next/link";
import styles from "./button.module.scss";

type Props = {
  type?: "button" | "submit" | "reset";
  theme?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "quinary"
    | "transparent";
  to?: string;
  href?: string;
  newPage?: boolean;
  onClick?: (args?: any) => void;
};

const Button: React.FC<Props> = (props) => {
  return (
    <>
      {!!props.to ? (
        <Link href={props?.to ?? ""} passHref={!!props.to}>
          <button
            onClick={props.onClick}
            type={props?.type ?? "button"}
            className={(() => {
              switch (props.theme) {
                case "secondary":
                  return styles["button__secondary"];
                case "tertiary":
                  return styles["button__tertiary"];
                case "transparent":
                  return styles["button__transparent"];
                default:
                  return styles["button__primary"];
              }
            })()}
          >
            <span>{props.children}</span>
          </button>
        </Link>
      ) : !!props.href ? (
        <a
          href={props?.href ?? ""}
          target={props.newPage ? "_blank" : ""}
          rel="noreferrer noopener"
        >
          <button
            onClick={props.onClick}
            type={props?.type ?? "button"}
            className={(() => {
              switch (props.theme) {
                case "secondary":
                  return styles["button__secondary"];
                case "tertiary":
                  return styles["button__tertiary"];
                case "transparent":
                  return styles["button__transparent"];
                default:
                  return styles["button__primary"];
              }
            })()}
          >
            <span>{props.children}</span>
          </button>
        </a>
      ) : (
        <button
          onClick={props.onClick}
          type={props?.type ?? "button"}
          className={(() => {
            switch (props.theme) {
              case "secondary":
                return styles["button__secondary"];
              case "tertiary":
                return styles["button__tertiary"];
              case "transparent":
                return styles["button__transparent"];
              default:
                return styles["button__primary"];
            }
          })()}
        >
          <span>{props.children}</span>
        </button>
      )}
    </>
  );
};

export default Button;
